import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container, Row, Col} from 'reactstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Circle from "../components/circle"

const shortcodes = {Circle, Row, Col}

const HomePage = () => {
  const data = useStaticQuery(graphql`
    query HomeQuery {
      allFile(sort: {order: ASC, fields: relativePath}, filter: {sourceInstanceName: {eq: "home"}, extension: {in: ["md", "mdx"]}}) {
        nodes {
          relativePath
          childMdx {
            body
            frontmatter {
              title
              hero {
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              image {
                childImageSharp {
                  fluid(quality: 80) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              cards {
                title
                subtitle
                body
                externalUrl
                image {
                  childImageSharp {
                    fluid(quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                note
              }
              background
              text
              fontSize
            }
          }
          extension
        }
      }
      banner: file(relativePath: {eq: "images/logo.jpg"}, sourceInstanceName: {eq: "home"}) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }  
  `)  

  const sections = data.allFile.nodes.filter((child) => child)
  const banner = data.banner.childImageSharp.fluid

  let sequence = 0;

  return (
    <Layout>
      <SEO title="Home" /> 
      <Container>
        <Row  className="py-5 min-vh-100 align-items-center">
            <Col sm="12" md="12" lg="12">
                <Img fluid={banner} />
            </Col>
          </Row>
      </Container>     
        { 
          sections.map(({childMdx}, index) => {           
            const {frontmatter, body} = childMdx
                          
            if(frontmatter.image) {              
              return (  
                <div key={index} style={{background:frontmatter.background}}>
                  <Container>                    
                      <Row  className="py-5 min-vh-100 align-items-center">
                        <Col sm="12" md="12" lg="12">
                            <Img fluid={frontmatter.image.childImageSharp.fluid}/>
                        </Col>                  
                      </Row>  
                  </Container>
                </div>              
              )
          } else if(frontmatter.hero) {

              const fontSize = frontmatter.fontSize || "2rem"

              const title = (
                <>
                  {frontmatter.title && 
                    <Col md="12">
                      <h1>{frontmatter.title}</h1>                        
                    </Col>                                    
                  }
                </>
              )

              const part1 = (
                <>
                  <Col sm="12" md="12" lg="6" style={{fontSize:fontSize}}>
                    <MDXProvider components={shortcodes}>                 
                      <MDXRenderer >{body}</MDXRenderer>
                    </MDXProvider>
                  </Col>
                </>
              )

              const part2 = (
                <Col sm="12" md="12" lg="6">
                    <Img fluid={frontmatter.hero.childImageSharp.fluid}/>
                </Col>
              )

              const child = sequence%2 === 0 ? [title,part1,part2] : [title,part2,part1]
              sequence +=1

              return (  
                <div key={index} style={{background:frontmatter.background, color: frontmatter.text}}>
                  <Container>                    
                      <Row  className="py-5 min-vh-100 align-items-center">
                        {child}                                                                  
                      </Row>  
                  </Container>
                </div>              
              )
          } else if(frontmatter.cards) {
            return (
              <div className="py-5" key={index} style={{background:frontmatter.background, color: frontmatter.text, minHeight: "100vh"}}>
                <Container>                  
                  <Row className="pt-3 align-items-center">                    
                    {frontmatter.cards.map((card, i) => {                        
                          let child = (
                            <>                              
                              {card.image && 
                                <Img fluid={card.image.childImageSharp.fluid} />
                              } 
                              <div className="text-center">
                                {card.title && 
                                  <h3>
                                    {card.title}
                                    {card.subtitle &&
                                      <>
                                      <br/>
                                      <small>{card.subtitle}</small>
                                      </>
                                    }
                                  </h3>
                                } 
                              </div>  
                              {card.body && 
                                <>                     
                                <div>                                  
                                    {card.body}
                                </div>            
                                </>
                              }                              
                            </>
                          )

                          if(card.externalUrl) {
                            child = (
                              <>
                                {card.note && 
                                  <>                     
                                    <div>                                  
                                        {card.note}
                                    </div>  
                                    <br/>          
                                  </>
                                }
                                <a href={card.externalUrl} target="_blank" rel="noopener norefferer">
                                    {child}
                                </a>
                              </>
                            )
                          } else if(card.url) {
                            child = (
                              <>
                                {card.note && 
                                  <>                     
                                    <div>                                  
                                        {card.note}
                                    </div>            
                                  </>
                                }
                                <Link to={card.url}>
                                    {child}
                                </Link>
                              </>
                            )
                          } 

                          return (
                            <Col className="py-2" lg="6" md="12" key={i}>                                                                
                              {child}
                            </Col>
                          )
                        })}
                  </Row>                    
                </Container> 
              </div>      
            )
          } 
            else {
              return (
                <div key={index} style={{background:frontmatter.background, color: frontmatter.text}}>
                  <Container >
                    <Row className="py-5 min-vh-100 align-items-center">
                      <Col>
                        <h1>{frontmatter.title}</h1>
                        <MDXProvider components={shortcodes}>
                          <MDXRenderer>{body}</MDXRenderer>
                        </MDXProvider>
                      </Col>
                    </Row>
                  </Container> 
                </div>
              )            
            }
            
          })
        }      
    </Layout>
  )
}

export default HomePage
