import React from "react"
import styled from "styled-components"

// from StackOverflow https://stackoverflow.com/a/51564734
const hex2rgba = (hex) => {
    return hex.match(/\w\w/g).map(x => parseInt(x, 16));    
};

let QuoteWrapper = styled.div`
    width: 285px;
    height: 285px;
    position: relative;
    // margin: 10vh auto 0; 
`
  
let Text = styled.div`
    width: 100%;
    height: 100%;
    background: ${(props) => {
        const [r, g, b] = hex2rgba(props.color)        
        return `radial-gradient(
                    ellipse at center, 
                    rgba(${r}, ${g}, ${b}, 1) 0%,
                    rgba(${r}, ${g}, ${b}, 1) 70%,
                    rgba(${r}, ${g}, ${b}, 0) 70.3%
                );`
    }}
    position: relative;
    margin: 0;
    color: black;

    ::before {
        content: "";
        width: 50%;
        height: 100%;
        float: left;
        shape-outside: polygon(
        0 0,
        98% 0,
        50% 6%,
        23.4% 17.3%,
        6% 32.6%,
        0 50%,
        6% 65.6%,
        23.4% 82.7%,
        50% 94%,
        98% 100%,
        0 100%
        );
        shape-margin: 7%;
    }
`
    
let P = styled.p`
    height: 100%;
    font-size: 18px;
    line-height: 1.25;
    padding: 0;
    text-align: center;
    // font-style: italic;
    // text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);

    ::before {
        content: "";
        width: 50%;
        height: 100%;
        float: right;
        shape-outside: polygon(
          2% 0%,
          100% 0%,
          100% 100%,
          2% 100%,
          50% 94%,
          76.6% 82.7%,
          94% 65.6%,
          100% 50%,
          94% 32.6%,
          76.6% 17.3%,
          50% 6%
        );
        shape-margin: 7%;
      }
`
  
//   @media (min-width: 850px) {
//     .quote-wrapper {
//       width: 370px;
//       height: 370px;
//     }
  
//     .quote-wrapper::before {
//       font-size: 300px;
//     }
  
//     .text p {
//       font-size: 26px;
//     }
  
//     .quote-wrapper blockquote footer {
//       bottom: 32px;
//     }
//   }
  

const Component = ({children, color}) => {
    return (
        <QuoteWrapper>
            <Text color={color}>
                <P>{children}</P>
            </Text>
        </QuoteWrapper>
    )   
}
 
 export default Component